/**
 * Create compatibility with Jquery since the version of perfectscrollbar dropped at version v1.0.0
 * @param options
 * @returns {*}
 */
jQuery.fn.perfectScrollbar = function (options) {
	if (!this[0] || !$(this[0]).length) {
		console.info('Perfect scroll did not found element "', this[0], '". The options passed was ', options);
		return false;
	}

	//If scroll is already created, should only receive methods
	if ($(this[0]).data('perfectScrollbar')) {
		switch (options) {
			case 'update':
				return $(this[0]).data('perfectScrollbar').update();
			case 'destroy':
				return $(this[0]).data('perfectScrollbar').destroy();
			default:
				return false; //Already has perfect scroll
		}
	}
	$(this[0]).data({
		perfectScrollbar: new PerfectScrollbar(this.selector || $(this).getSelector()[0], options)
	});
};
